<template>
  <div class="videoPage">
<headerPage></headerPage>
<serchPage @serch="serchGrow"></serchPage>
<div class="video-m">
      <div class="video-box">
        <div class="video-item">
            <div class="video-title">
          <div class="video-left">
            <span>就业分享</span>
          </div>
          <div class="video-right" @click="link('/employment')">
            <span>查看更多</span>
            <img :src="path + 'imgs/image/home/3.png'" alt="">
          </div>
          </div>

          <div class="video_model">
            <div class="video-items">
              <div class="video-img" @click="$router.push({path:'/videoDetails',query:{sign:jyfx.sign,id:item.id}})"  v-for="(item,index) in jyfx.fir" :key="index" >
              <div class="video">
                <img :src="path+'imgs/image/video/6.png'" alt="">
                <video :src="url+item.url" width="100%" height="100%" style="object-fit: fill;"></video>
              </div>
              <div class="video-name">
                <span>{{ item.title }}</span>
              </div>
            </div>
            </div>
            <div class="video-list">
              <ul>
                <li v-for="item in jyfx.sec" :key="item"  @click="$router.push({path:'/videoDetails',query:{sign:jyfx.sign,id:item.id}})"><p>{{ item.title }}</p></li>
              </ul>
            </div>
          </div>

        </div>
        <div class="video-item">
            <div class="video-title">
          <div class="video-left">
            <span>面试分享</span>
          </div>
          <div class="video-right" @click="link('/interview')">
            <span>查看更多</span>
            <img :src="path + 'imgs/image/home/3.png'" alt="">
          </div>
          </div>
          <div class="video_model">
            <div class="video-items">
              <div class="video-img" @click="$router.push({path:'/videoDetails',query:{sign:msfx.sign,id:item.id}})"  v-for="(item,index) in msfx.fir" :key="index" >
              <div class="video">
                <img :src="path+'imgs/image/video/6.png'" alt="">
                <video :src="url+item.url" width="100%" height="100%" style="object-fit: fill;"></video>
              </div>
              <div class="video-name">
                <span>{{ item.title }}</span>
              </div>
            </div>
            </div>
            <div class="video-list">
              <ul>
                <li v-for="item in msfx.sec" :key="item"  @click="$router.push({path:'/videoDetails',query:{sign:msfx.sign,id:item.id}})">{{ item.title }}</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="video-item">
            <div class="video-title">
          <div class="video-left">
            <span>校友分享</span>
          </div>
          <div class="video-right" @click="link('/sharing')">
            <span>查看更多</span>
            <img :src="path + 'imgs/image/home/3.png'" alt="">
          </div>
          </div>
          <div class="video_model">
            <div class="video-items">
              <div class="video-img" @click="$router.push({path:'/videoDetails',query:{sign:xyfx.sign,id:item.id}})"  v-for="(item,index) in xyfx.fir" :key="index" >
              <div class="video">
                <img :src="path+'imgs/image/video/6.png'" alt="">
                <video :src="url+item.url" width="100%" height="100%" style="object-fit: fill;"></video>
              </div>
              <div class="video-name">
                <span>{{ item.title }}</span>
              </div>
            </div>
            </div>
            <div class="video-list">
              <ul>
                <li v-for="item in xyfx.sec" :key="item"  @click="$router.push({path:'/videoDetails',query:{sign:xyfx.sign,id:item.id}})">{{ item.title }}</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="video-item">
            <div class="video-title">
          <div class="video-left">
            <span>他山之石</span>
          </div>
          <div class="video-right" @click="link('/shortcoming')">
            <span>查看更多</span>
            <img :src="path + 'imgs/image/home/3.png'" alt="">
          </div>
          </div>
          <div class="video_model">
            <div class="video-items">
              <div class="video-img" @click="$router.push({path:'/videoDetails',query:{sign:tszs.sign,id:item.id}})"  v-for="(item,index) in tszs.fir" :key="index" >
              <div class="video">
                <img :src="path+'imgs/image/video/6.png'" alt="">
                <video :src="url+item.url" width="100%" height="100%" style="object-fit: fill;"></video>
              </div>
              <div class="video-name">
                <span>{{ item.title }}</span>
              </div>
            </div>
            </div>
            <div class="video-list">
              <ul>
                <li v-for="item in tszs.sec" :key="item"  @click="$router.push({path:'/videoDetails',query:{sign:jyfx.sign,id:item.id}})">{{ item.title }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
</div>
<bottomPage></bottomPage>
  </div>
</template>

<script setup>
import headerPage from '@/components/headerPage.vue'
import serchPage from '@/components/serchPage.vue'
import bottomPage from '@/components/bottomPage.vue'
// import '@/assets/css/videopage/videoPage.scss'
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import { getVideoList } from '@/api/video'
const router = useRouter()
const path = ref(localStorage.getItem('path')).value
const jyfx = ref('')
const msfx = ref('')
const xyfx = ref('')
const tszs = ref('')
const url = localStorage.getItem('url')
const link = (link) => {
  router.push(link)
}
const getSerchs = (val) => {
  const res = getVideoList(val)

  res.then((response) => {
    if (response.data && response.data.data) {
      jyfx.value = response.data.data.jyfx
      msfx.value = response.data.data.msfx
      xyfx.value = response.data.data.xyfx
      tszs.value = response.data.data.tszs
    } else {
      // 如果没有找到任何结果，显示错误消息
      ElMessage.error('没有找到')
    }
  })
}
const serchGrow = async (val) => {
  router.push({ path: '/serch', query: { keywods: val, sign: 3 } })
}
onMounted(() => {
  getSerchs()
})
</script>

<style lang="scss" src="@/assets/css/videopage/videoPage.scss" scoped>

</style>
