import request from '@/utils/request'
// 所在学校
export const getSchool = () => {
  return request.get('/api/fillin/school')
}
// 所学专业
export const getMajor = () => {
  return request.get('/api/fillin/major')
}
// 所在岗位
export const getPost = () => {
  return request.get('/api/fillin/post')
}
// 所在行业
export const getIndustry = () => {
  return request.get('/api/fillin/industry')
}
// 数据提交
export const sendData = ({ username, alias, birthday, gender, identity, school, majorf, majors, majort, year, stage, unit, post, industry, years }) => {
  return request.post('/api/fillin/add', {
    username,
    alias,
    birthday,
    gender,
    identity,
    school,
    majorf,
    majors,
    majort,
    year,
    stage,
    unit,
    post,
    industry,
    years
  }
  )
}
