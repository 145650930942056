<template>
  <div class="box">
    <div class="header">
      <div class="header-box">
        <img :src="path + 'imgs/image/login/10.png'" alt="" />
      </div>
    </div>
    <div class="title">
      <div class="title-box">
        <p>欢迎来到职小树!</p>
      </div>
    </div>
    <div class="main">
      <div class="main-box">
        <div class="main-title">
          <p>填写基本信息</p>
        </div>
        <div class="usermessage">
          <el-form
            :label-position="labelPosition"
            label-width="auto"
            :model="form"
            style="width: 800px"
          >
            <el-form-item label="姓名">
              <span class="required-mark" style="left: -50px">*</span>
              <el-input v-model="form.username" placeholder="请输入您的姓名" />
            </el-form-item>
            <el-form-item label="化名">
              <span class="required-mark" style="left: -50px">*</span>
              <el-input v-model="form.alias" placeholder="请输入您的化名" maxlength="5" />
            </el-form-item>
            <el-form-item label="出生日期">
              <span class="required-mark" style="left: -80px">*</span>
              <el-date-picker
                v-model="form.birthday"
                type="date"
                placeholder="请选择您的出生日期"
                :shortcuts="shortcuts"
                :size="size"
                value-format="YYYY-MM-DD"
              />
            </el-form-item>
            <el-form-item label="性别">
              <span class="required-mark" style="left: -50px">*</span>
              <div class="sex">
                <div class="nan" @click="sex(1)">
                  <div class="radio1" :class="{ current: resource == 1 }"></div>
                  <span>男</span>
                </div>
                <div class="nv" @click="sex(2)">
                  <div class="radio2" :class="{ current: resource == 2 }"></div>
                  <span>女</span>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="你的身份">
              <span class="required-mark" style="left: -80px">*</span>
              <div class="identity">
                <div
                  class="Workplace"
                  @click="identity(2)"
                  :class="{ active: identitys == 2 }"
                >
                  <span>在校生</span>
                </div>
                <div
                  class="student"
                  @click="identity(3)"
                  :class="{ active: identitys == 3 }"
                >
                  <span> 职场人 </span>
                </div>
              </div>
            </el-form-item>
            <div class="xian"></div>
            <div class="WorkplaceMes" v-show="identitys == 3">
              <el-form-item label="所在单位">
                <span class="required-mark" style="left: -80px">*</span>
                <el-input
                  v-model="form.unit"
                  placeholder="请输入您的所在单位名称"
                />
              </el-form-item>
              <el-form-item label="所在岗位">
                <span class="required-mark" style="left: -80px">*</span>
                <el-autocomplete
                  v-model="postValue"
                  :fetch-suggestions="postValueSearch"
                  placeholder="请选择您的所在岗位"
                  style="width: 380px"
                  @select="postVal"
                  @change="postIpt"
                  value-key="name"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item label="所在行业">
                <span class="required-mark" style="left: -80px">*</span>
                <el-autocomplete
                  v-model="industryValue"
                  :fetch-suggestions="industrySearch"
                  placeholder="请选择您的所在行业"
                  style="width: 380px"
                  @select="industryVal"
                  @change="industryIpt"
                  value-key="name"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item label="工作年限">
                <span class="required-mark" style="left: -80px">*</span>
                <el-input
                  v-model.number="form.years"
                  placeholder="请输入您的工作年限"
                />
              </el-form-item>
            </div>
            <div class="studens" v-show="identitys == 2">
              <el-form-item label="所在学校">
                <span class="required-mark" style="left: -80px">*</span>
                <el-autocomplete
                  v-model="school"
                  :fetch-suggestions="schoolSearch"
                  placeholder="请选择您的所在学校"
                  style="width: 380px"
                  @select="schoolVal"
                  @change="schoolIpt"
                  value-key="name"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item label="所学专业">
                <span class="required-mark" style="left: -80px">*</span>
                <!-- <el-autocomplete
                  v-model="majorValue"
                  :fetch-suggestions="majorSearch"
                  placeholder="请选择您的所学专业"
                  style="width: 380px"
                  @select="majorVal"
                  @change="majorIpt"
                  value-key="name"
                ></el-autocomplete> -->
                <el-cascader   v-model="majorValue" :options="major" ref="cascaderPanelRef" :props="{label:'name' ,children:'childs' ,value:'id'}" @change="handleChange" />
              </el-form-item>
              <el-form-item label="入学年份">
                <span class="required-mark" style="left: -80px">*</span>
                <el-input
                  v-model.number="form.year"
                  placeholder="请输入您的入学年份"
                />
              </el-form-item>
              <el-form-item label="在校阶段">
                <span class="required-mark" style="left: -80px">*</span>
                <div class="identity">
                  <div
                    class="Workplace"
                    @click="stage(1)"
                    :class="{ active: stages == 1 }"
                  >
                    <span>本科生</span>
                  </div>
                  <div
                    class="student"
                    @click="stage(2)"
                    :class="{ active: stages == 2 }"
                  >
                    <span> 硕士 </span>
                  </div>
                  <div
                    class="Workplace"
                    @click="stage(3)"
                    :class="{ active: stages == 3 }"
                  >
                    <span>博士</span>
                  </div>
                  <div
                    class="Workplace"
                    @click="stage(4)"
                    :class="{ active: stages == 4 }"
                  >
                    <span>博士后</span>
                  </div>
                </div>
              </el-form-item>
            </div>
            <div class="btn">
              <el-button type="primary" @click="send()" color="#15b6f0" style="color: white !important;">提交</el-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import '../assets/css/student/student.scss'
import { onMounted, reactive, ref } from 'vue'
import {
  getSchool,
  getMajor,
  getPost,
  getIndustry,
  sendData
} from '@/api/fillin'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import { getinfo } from '@/utils/storge'
import store from '@/store'
const path = localStorage.getItem('path')
const router = useRouter()
const resource = ref(1)
const identitys = ref(2)
// const birthday = ref('') // 出生日期
const postValue = ref('') // 专业关键字
const postList = ref([]) // 专业列表
const stages = ref(1) // 在校阶段：1=本科生2=硕士3=博士4=博士后
const school = ref('') // 学校关键字
const schoolList = ref([]) // 学校列表
const majorValue = ref('') // 专业关键字
const major = ref([]) // 专业列表
const industryList = ref([]) // 行业列表
const industryValue = ref('') // 行业关键字
const form = reactive({
  username: '', // 姓名
  alias: '', // 化名
  birthday: '', // 出生日期
  gender: Number(1), // 1男2女
  identity: Number(2), // 2在校3职场
  school: school.value, // 所在学校：下拉选择传id，手动填写传填写内容
  majorf: '',
  majors: '',
  majort: '',
  year: '', // 入学年份
  stage: stages.value, // 在校阶段：1=本科生2=硕士3=博士4=博士后
  unit: '', // 所在单位名称
  post: '', // 所在岗位：下拉选择传id，手动填写传填写内容
  industry: '', // 所在行业：下拉选择传id，手动填写传填写内容,
  years: '' // 工作年限：>=0
})
const cascaderPanelRef = ref(null)
const sex = (key) => {
  form.gender = Number(key)
  resource.value = key
}
const identity = (key) => {
  form.identity = Number(key)
  identitys.value = key
}
const stage = (key) => {
  form.stage = Number(key)
  stages.value = key
}
// 所在学校
const schoolChange = async () => {
  const res = await getSchool()
  schoolList.value = res.data.data
}
const schoolIpt = (val) => {
  school.value = val
  form.school = val
}
const schoolVal = (val) => {
  school.value = val.name
  form.school = val.id
}
const schoolSearch = (queryString, callback) => {
  const restaurants = schoolList.value
  const results = queryString
    ? restaurants.filter(createFilter(queryString))
    : restaurants
  callback(results)
}
// 所学专业
const Majors = async () => {
  const res = await getMajor()
  major.value = res.data.data
}
// const majorSearch = (queryString, callback) => {
//   const restaurants = major.value
//   const results = queryString
//     ? restaurants.filter(createFilter(queryString))
//     : restaurants
//   callback(results)
// }
// const majorIpt = (val) => {
//   majorValue.value = val
//   form.major = val
// }
// const majorVal = (val) => {
//   majorValue.value = val.name
//   form.major = val.id
//   console.log(val)
// }
const handleChange = (val) => {
  form.majorf = val[0]
  form.majors = val[1]
  form.majort = val[2]
}
// 所在岗位
const getPostList = async () => {
  const res = await getPost()
  postList.value = res.data.data
}
const postValueSearch = (queryString, callback) => {
  const restaurants = postList.value
  const results = queryString
    ? restaurants.filter(createFilter(queryString))
    : restaurants
  callback(results)
}
const postIpt = (val) => {
  postValue.value = val
  form.post = val
}
const postVal = (val) => {
  postValue.value = val.name
  form.post = val.id
}
// 所在行业
const getindustry = async () => {
  const res = await getIndustry()
  industryList.value = res.data.data
}
const industrySearch = (queryString, callback) => {
  const restaurants = industryList.value
  const results = queryString
    ? restaurants.filter(createFilter(queryString))
    : restaurants
  callback(results)
}
const industryIpt = (val) => {
  industryValue.value = val
  form.industry = val
}
const industryVal = (val) => {
  industryValue.value = val.name
  form.industry = val.id
}
const createFilter = (queryString) => {
  return (restaurant) => {
    return (
      restaurant.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0
    )
  }
}
const send = async () => {
  if (form.username === '') {
    ElMessage.error('请输入姓名')
    return
  }
  if (form.hm === '') {
    ElMessage.error('请输入化名')
    return
  }
  if (form.birthday === '') {
    ElMessage.error('请输入出生日期')
    return
  }
  if (form.identity === 2) {
    if (form.school === '') {
      ElMessage.error('请输入所在学校')
      return
    } else if (!form.majorf || !form.majors || !form.majort) {
      ElMessage.error('请输入所学专业')
      return
    } else if (!/^\d{4}$/.test(form.year)) {
      ElMessage.error('请输入入学年份 如:2001')
      return
    }
  }
  if (form.identity === 3) {
    if (form.unit === '') {
      ElMessage.error('请输入所在单位名称')
      return
    } else if (form.post === '') {
      ElMessage.error('请输入所在岗位')
      return
    } else if (form.industry === '') {
      ElMessage.error('请输入所在行业')
      return
    } else if (form.years === '') {
      ElMessage.error('请输入工作年限,数字类型')
      return
    }
  }
  const res = await sendData(form)
  if (res.data.code === 1) {
    ElMessage.success('成功')
    const userInfo = getinfo()
    userInfo.fillin = 1
    userInfo.username = form.username
    const { token, fillin, username, avatar } = userInfo
    const alias = form.alias
    store.commit('user/setUserInfo', { token, fillin, username, avatar, alias })
    router.push('/')
  }
}
onMounted(() => {
  schoolChange()
  Majors()
  getPostList()
  getindustry()
})
</script>

<style lang="scss" scoped>
</style>
