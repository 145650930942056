
<template>
  <div class="header-index">
    <div class="header-box">
      <div class="logo" @click="$router.push('/')">
        <img :src="path + 'imgs/image/login/10.png'" alt="">
      </div>
      <div class="tab-list">
        <ul>
          <li v-for="item in link" :key="item">
            <router-link :to="item.path" class="a">{{ item.value }}</router-link>
          </li>
        </ul>
      </div>
      <div class="user">
        <div class="avatar" v-if='$store.getters.token'>
          <img v-lazy="url+$store.getters.avatar" alt="">
        </div>
        <div class="name">
          <span>{{ $store.getters.alias}}</span>
          <div v-if="$store.getters.hyid==0">
          <p style="font-size: 12px; text-decoration: underline;" @click="$router.push('/vip')">开通超级会员 获取更多权益</p>
          </div>
          <img style="height: 15px;" src="/imgs/image/center/cjhy.png" alt="" v-if="$store.getters.hyid==1">
          <img style="height: 15px;"  v-if="$store.getters.hyid===2" src="/imgs/image/center/zshy.png" alt="">
          <img style="height: 15px;"  v-if="$store.getters.hyid===3" src="/imgs/image/center/hypro.png" alt="">
        </div>
        <div class="quit" v-if="$store.getters.token">
          <span @click="$router.push('/summary')">简历中心</span>
          <span @click="$router.push('/storage')">我的收藏</span>
          <span @click="$router.push('/message')">我的消息</span>
          <span @click="$router.push('/changepassword')">账号设置</span>
          <span  @click="quit">退出登录</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import '@/assets/css/headerPage/headerPage.scss'
import { ref } from 'vue'
// import { loginOut } from '@/api/login'
import { removeinfo, setinfo } from '@/utils/storge'
// import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import store from '@/store'
const router = useRouter()
const path = ref(localStorage.getItem('path')).value
const url = ref(localStorage.getItem('url'))
const link = ref([
  {
    path: '/',
    value: '首页'
  },
  {
    path: '/course',
    value: store.getters.identity === '2' ? '大学历程' : '职场历程'
  },
  {
    path: '/grow',
    value: '成长路径'
  },
  {
    path: '/video',
    value: '视频区'
  },
  {
    path: '/post',
    value: '岗位区'
  },
  {
    path: '/area',
    value: '问答区'
  },
  {
    path: '/question',
    value: '真题区'
  },
  {
    path: '/personal',
    value: '个人中心'
  }
])
const quit = async () => {
  removeinfo()
  setinfo({})
  store.commit('user/setUserInfo', {})
  router.push('/login')
  // const res = await loginOut()
  // ElMessage.success(res.data.msg)
}
</script>

<style>
</style>
