<template>
  <div class="serch-m">
    <div class="serch-box">
      <div class="serch">
        <el-input v-model="serch" size="small" placeholder="请输入关键字" :prefix-icon="Search" />
        <div class="button" @click="serchClick">
          <p>搜索</p>
        </div>
      </div>
      <div class="serch-list">
        <p>热门关键词搜索：</p>
        <span @click="serchGjz('升学')">升学</span>
        <span @click="serchGjz('简历')">简历</span>
        <span @click="serchGjz('面试')">面试</span>
        <span @click="serchGjz('跳槽')">跳槽</span>
      </div>
      <slot name="asking"></slot>
    </div>
  </div>
</template>

<script setup>
import { Search } from '@element-plus/icons-vue'
import { ref, defineEmits, defineProps, watch } from 'vue'
const serch = ref('')
const emit = defineEmits(['serch'])
const props = defineProps({
  keywords: {
    type: String,
    default: ''
  }
})
const serchClick = () => {
  emit('serch', serch.value)
}
const serchGjz = (val) => {
  emit('serch', val)
}
watch(() => props.keywords, () => {
  serch.value = props.keywords
})
</script>

<style lang="scss" scoped>
.serch-m {
  width: 100%;
  height: 80px;
}

.serch-box {
  width: 1190px;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  .serch {
    width: 722px;
    height: 52px;
    background-color: #15B6F0;
    float: left;
    display: flex;
    border-radius: 5px;
    align-items: center;
    justify-content: space-between;

    // margin-left: 70px;
    .el-input {
      width: 763px;
      height: 48px;
      margin-left: 2px;
    }

    .button {
      width: 110px;
      height: 100%;
      color: white;
      text-align: center;
      line-height: 52px;
      cursor: pointer;

    }
  }

  .serch-list {
    width: 190px;
    height: auto;
    margin-left: 30px;

    p:nth-child(1) {
      color: #15B6F0;
    }

    span {
      font-size: 14px;
      margin-right: 10px;
      cursor: pointer;
    }
  }
}
</style>
