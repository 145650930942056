import { createRouter, createWebHashHistory } from 'vue-router'
import LoginHome from '@/views/LoginHome.vue'
import LoginPolicy from '@/views/LoginPolicy.vue'
// 学生信息页面
import student from '@/views/studentIndex.vue'
// 主页
import homePage from '@/views/homePage.vue'
// 大学历程
import course from '@/views/coursePage.vue'
// 成长路径
import grow from '@/views/growPage.vue'
// 视频区
import video from '@/views/videoPage.vue'
// 岗位区
import post from '@/views/postPage.vue'
// 问答区
import area from '@/views/areaPage.vue'
// 真题区
import question from '@/views/questionPage.vue'
import store from '@/store'
import { ElMessage } from 'element-plus'
// import { getinfo } from '@/utils/storge'
const routes = [
  {
    path: '/',
    name: 'homePage',
    component: homePage
  },
  {
    path: '/loginpolicy',
    name: 'LoginPolicy',
    component: LoginPolicy

  },
  {
    path: '/student',
    name: 'student',
    component: student
  },
  {
    path: '/login',
    name: 'LoginHome',
    component: LoginHome
  },
  {
    path: '/course',
    name: 'course',
    component: course
  },
  {
    path: '/grow',
    name: 'grow',
    component: grow
  },
  {
    path: '/video',
    name: 'video',
    component: video
  },
  {
    path: '/post',
    name: 'post',
    component: post
  },
  {
    path: '/area',
    name: 'area',
    component: area
  },
  {
    path: '/question',
    name: 'question',
    component: question
  },
  // 个人中心
  {
    path: '/personal',
    name: 'personal',
    component: () => import('@/views/personalPage.vue')
  }, {
    path: '/continuing',
    name: 'continuing',
    component: () => import('@/views/ContinuingPage.vue')
  },
  {
    path: '/alumni',
    name: 'alumni',
    component: () => import('@/views/AlumniPage.vue')
  }, {
    path: '/occupation',
    name: 'occupation',
    component: () => import('@/views/occupationPage.vue')
  },
  {
    path: '/story',
    name: 'story',
    component: () => import('@/views/storyPage.vue')
  },
  {
    path: '/articledetails',
    name: 'articledetails',
    component: () => import('@/views/ArticleDetails.vue')
  },
  {
    path: '/employment',
    name: 'employment',
    component: () => import('@/views/employmentPage.vue')
  },
  {
    path: '/interview',
    name: 'interview',
    component: () => import('@/views/InterviewPage.vue')
  },
  {
    path: '/sharing',
    name: 'sharing',
    component: () => import('@/views/sharingPage.vue')
  }, {
    path: '/shortcoming',
    name: 'shortcoming',
    component: () => import('@/views/shortcomingPage.vue')
  }, {
    path: '/videoDetails',
    name: 'videoDetails',
    component: () => import('@/views/videoDetails.vue')
  },
  {
    path: '/unit',
    name: 'unit',
    component: () => import('@/views/unitPage.vue')
  },
  {
    path: '/company',
    name: 'company',
    component: () => import('@/views/companyPage.vue')
  }, {
    path: '/latestposition',
    name: 'latestposition',
    component: () => import('@/views/LatestPosition.vue')
  }, {
    path: '/jobdetails',
    name: 'jobdetails',
    component: () => import('@/views/jobDetails.vue')
  }, {
    path: '/dynamic',
    name: 'dynamic',
    component: () => import('@/views/dynamicPage.vue')
  }, {
    path: '/feel',
    name: 'feel',
    component: () => import('@/views/feelPage.vue')
  },
  {
    path: '/questions',
    name: 'questions',
    component: () => import('@/views/questionsDetails.vue')
  }, {
    path: '/submitquestions',
    name: 'submitquestions',
    component: () => import('@/views/submitQuestions.vue')
  },
  {
    path: '/information',
    name: 'information',
    component: () => import('@/views/informationPage.vue')
  },
  {
    path: '/launch',
    name: 'launch',
    component: () => import('@/views/launchPage.vue')
  }, {
    path: '/note',
    name: 'note',
    component: () => import('@/views/noteList.vue')
  }, {
    path: '/noteDetails',
    name: 'noteDetails',
    component: () => import('@/views/noteDetails.vue')
  },
  {
    path: '/boxes',
    name: 'boxes',
    component: () => import('@/views/Boxes/BoxesPage.vue')

  }, {
    path: '/boxesname',
    name: 'boxesname',
    component: () => import('@/views/Boxes/BoxesName.vue')
  }, {
    path: '/dynamics',
    name: 'dynamics',
    component: () => import('@/views/DynamicsDetails.vue')
  }, {
    path: '/wbook',
    name: 'wbook',
    component: () => import('@/views/WhiteBooks.vue')
  }, {
    path: '/major',
    name: 'major',
    component: () => import('@/views/marjorPage.vue')
  }, {
    path: '/majoronbook',
    name: 'majoronbook',
    component: () => import('@/views/MajorBook.vue')
  }, {
    path: '/obtain',
    name: 'obtain',
    component: () => import('@/views/obtainPage.vue')
  }, {
    path: '/companies',
    name: 'companies',
    component: () => import('@/views/CompaniesPage.vue')
  }, {
    path: '/summary',
    name: 'summary',
    component: () => import('@/views/summaryPage.vue')
  }, {
    path: '/storage',
    name: 'storage',
    component: () => import('@/views/storagePage.vue')
  }, {
    path: '/message',
    name: 'message',
    component: () => import('@/views/MyMessage.vue')
  },
  {
    path: '/vip',
    name: 'vip',
    component: () => import('@/views/VipPage.vue')
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: () => import('@/views/forgotpassword/forgotPasssword.vue')
  },
  {
    path: '/changepassword',
    name: 'changepassword',
    component: () => import('@/views/ChangePassword/ChangePassword.vue')
  },
  {
    path: '/zymx',
    name: 'zymx',
    component: () => import('@/views/zymxList.vue')
  },
  {
    path: '/zymxdetails',
    name: 'zymxdetails',
    component: () => import('@/views/zymxDetails.vue')
  },
  {
    path: '/dxlc',
    name: 'dxlc',
    component: () => import('@/views/dxlcList.vue')
  },
  {
    path: '/dxlcdetails',
    name: 'dxlcdetails',
    component: () => import('@/views/dxlcDetails.vue')
  },
  {
    path: '/zyal',
    name: 'zyal',
    component: () => import('@/views/zyalDetails.vue')
  },
  {
    path: '/ztqwz',
    name: 'ztqwz',
    component: () => import('@/views/ztqWz.vue')
  },
  // {
  //   path: '/serch',
  //   name: 'serch',
  //   component: () => import('@/views/serchPage.vue')
  // },
  // {
  //   path: '/serchvideo',
  //   name: 'serchvideo',
  //   component: () => import('@/views/serchVadio.vue')
  // },
  // {
  //   path: '/serchpost',
  //   name: 'serchpost',
  //   component: () => import('@/views/serchPost.vue')
  // },
  // {
  //   path: '/serchztq',
  //   name: 'serchztq',
  //   component: () => import('@/views/serchZtq.vue')
  // },
  // {
  //   path: '/serchwdq',
  //   name: 'serchwdq',
  //   component: () => import('@/views/serchWdq.vue')
  // },
  {
    path: '/serch',
    name: 'serch',
    component: () => import('@/views/allSerchPage.vue')
  }, {
    path: '/zydt',
    name: 'zydt',
    component: () => import('@/views/zydtList.vue')
  },

  {
    path: '/:catchAll(.*)',
    name: 'not-found',
    component: () => import('@/404/NotFound.vue')
  }

]
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach((to, from, next) => {
//   const token = store.getters.token // 是否登录
//   const fillin = Number(store.getters.fillin) // 是否填写信息

//   if (!token) {
//     if (to.path !== '/' && to.path !== '/homePage' && to.path !== '/forgot') {
//       ElMessage.warning('请先登录')
//       next('/')
//     } else {
//       next()
//     }
//   } else {
//     if (fillin) {
//       if (to.path === '/student' || to.path === '/') {
//         next('/homePage')
//       } else {
//         next()
//       }
//     } else {
//       if (to.path !== '/student') {
//         next('/student')
//       } else {
//         next()
//       }
//     }
//   }
// })
// loginpolicy//用户协议页面

router.beforeEach((to, from, next) => {
  const token = store.getters.token // 是否登录
  const fillin = Number(store.getters.fillin) // 是否填写信息
  if (token) {
    if (fillin) {
      if (to.path === '/student' || to.path === '/login') {
        next('/')
      } else {
        next()
      }
    } else {
      if (to.path !== '/student') {
        next('/student')
      } else {
        next()
      }
    }
  } else {
    if (to.path === '/' || to.path === '/login' || to.path === '/forgot' || to.path === '/loginpolicy') {
      next()
    } else {
      ElMessage.warning('请先登录')
      next('/login')
    }
  }
})
router.afterEach((to, from, next) => {
  window.scroll(0, 0)
})
export default router
