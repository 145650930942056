<template>
  <div>
    <div class="header">
      <div class="header-box">
        <img :src="path+'imgs/image/login/10.png'" alt="" />
      </div>
    </div>
    <div class="content">
      <div class="content-box">
        <div class="content-tab">
          <div
            @click="tab(index, item.id)"
            v-for="(item, index) in notece"
            :class="{ active: i == item.id }"
            :key="item.id"
          >
            <span>
              {{ item.title }}
            </span>
          </div>
        </div>
        <div class="content-text">
          <div v-html="content.content" style="width: 894px; margin: auto; padding-bottom: 20px;"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import '@/assets/css/LoginPolicy/LoginPolicy.scss'
import { onMounted, ref, reactive } from 'vue'
import { useRoute } from 'vue-router'
import { UserNotece } from '@/api/login'

const route = useRoute()
const i = ref(route.query.id)
const notece = ref([]) // 初始化为空数组
const content = reactive({ content: '' }) // 初始化 content 属性
const path = localStorage.getItem('path')
const tab = async (key, id) => {
  i.value = id
  await fetchNotece(id) // 调用新的函数 fetchNotece
}
// 用户协议请求
const fetchNotece = async (id) => {
  await UserNotece().then(res => {
    notece.value = res.data.data
    const note = notece.value.find((item) => item.id === id)
    if (note) {
      content.content = note.content
    }
  })
}

onMounted(() => {
  const id = Number(route.query.id)
  fetchNotece(id)
})
</script>

<style scoped>
</style>
