<template>
  <div id="app">
    <router-view/>

  </div>
</template>
<script setup>
</script>
<style lang="scss">
@font-face {
    font-family: 'Myfont';
    src: url('./assets/font/Alibaba-PuHuiTi-Regular.otf');
}
body{
  width: 100%;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-family: 'Myfont','VideoJs' !important;

}
html,body,#app{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

// .el-button{
//   color: white !important;
// }
</style>
