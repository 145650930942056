import router from '@/router'
import axios from 'axios'
import { ElMessage } from 'element-plus'
import { getinfo, removeinfo } from './storge'
const instance = axios.create({
  baseURL: path,
  timeout: 300000
})

instance.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  if (getinfo().token) {
    // config.headers.Authorization = 'Bearer ' + getinfo().token
    config.headers.token = getinfo().token
  }
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
  // 2xx 范围内的状态码都会触发该函数。
  // 对响应数据做点什么

  return response
}, function (error) {
  // 超出 2xx 范围的状态码都会触发该函数。
  // 对响应错误做点什么
  if (error.response.status === 401) {
    removeinfo()
    ElMessage.error('重新登录')
    router.push('/login')
    location.reload()
  }
  return Promise.reject(error)
})
export default instance
