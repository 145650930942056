<template>
  <div class="sss">
    <Head></Head>
    <div class="banner" v-if="$store.getters.token">
    </div>
    <div class="bnner" v-else>
      <div class="bnner-box">
        <div class="login-right" v-if="!$store.getters.token">
          <div class="login-box">
            <div class="code" @click="changeFlag">
              <img v-if="!flag" :src="path + 'imgs/image/login/7.png'" alt="" />
              <img v-else :src="path + 'imgs/image/login/22.png'" alt="" />
            </div>
            <div v-if="!flag">
              <div class="login-title">
                <div @click="changeTab(1)" :class="{ current: loginTab }">
                  登录/注册
                </div>
                <span>|</span>
                <div @click="changeTab(0)" :class="{ current: !loginTab }">
                  密码登录
                </div>
              </div>
              <div class="divLogin" v-if="loginTab">
                <div class="input">
                  <!-- <select name="" id="" v-model="countrycodes">
                <option style="height: 50px" v-for="item in Countrycodes" :key="item.id" value="item.id">{{ item.code }}</option>
              </select>
              <span style="color: #969696">|</span>
              <input type="text" placeholder="手机号码" v-model="mobile"/> -->
                  <el-input
                    v-model="mobile"
                    placeholder="请输入手机号"
                  >
                    <template #prepend>
                      <el-select
                        v-model="countrycodes"
                        placeholder="区号"
                        style="width: 85px"
                        :fit-input-width="true"
                        class="qh"
                      >
                        <el-option :label="item.code"  v-for="item in Countrycodes" :key="item.id" :value="item.code" />
                      </el-select>
                    </template>

                  </el-input>
                </div>
                <div class="getcode1">
                  <div class="inputCode1">
                    <input type="text" placeholder="短信验证码" v-model="yzm" />
                  </div>
                  <div class="btnCode1" @click="Send">
                    <button :disabled="btn">
                      {{ m < 60 ? `重新获取(${m})` : "获取验证码" }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="divLogin" v-if="!loginTab">
                <div class="mobile-login1">
                  <input
                    style="width: 100%"
                    placeholder="请输入手机号码"
                    v-model="mobile"
                  />
                </div>
                <div class="password1">
                  <input
                    style="width: 100%"
                    placeholder="请输入密码"
                    v-model="password"
                    type="password"
                  />
                </div>
              </div>
              <div class="login1">
                <button @click="logins(loginTab)">
                  {{ loginTab ? "登录/注册" : "登录" }}
                </button>
              </div>
              <div class="checkbox1">
                <input type="checkbox" v-model="agree" />
                <span>我已阅读并同意</span>
                <span style="color: #15b6f0; cursor: pointer"
                  >《用户协议》</span
                >
                <span
                  style="color: #15b6f0; cursor: pointer"
                  @click="$router.push('policy?type=1')"
                  >《登录政策》</span
                >
                <span style="color: #15b6f0; cursor: pointer"
                  >《隐私条款》</span
                >
              </div>
              <div
                class="forget1"
                v-show="!loginTab"
                @click="$router.push('/forgot')"
              >
                <span>忘记密码?</span>
              </div>
            </div>
            <div class="saoCode1" v-if="flag">
              <p>扫码登录</p>
              <div class="QRcode1">
                <img :src="xcxQrcode" alt="" />
              </div>
              <div class="openwx1">
                <div class="openleft">
                  <img :src="path + 'imgs/image/login/55.png'" alt="" />
                </div>
                <div class="openright">
                  <p>打开微信"扫一扫"</p>
                  <p>扫描二维码快速登录</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-m">
      <div class="main-m-box" v-if="dxlc">
        <div class="main-title">
          <div class="title-left">
            <span>{{  $store.getters.identity === '2' ? '大学历程' : '职场历程'  }}</span>
          </div>
          <div class="title-right" @click="$router.push('/course')">
            <span>查看更多</span>
            <img :src="path + 'imgs/image/home/3.png'" alt="" />
          </div>
        </div>
        <div class="sage1">
          <div v-for="item in dxlc" :key="item.id">{{ item.title }}</div>
        </div>
        <div class="plate">
          <div v-for="item in dxlc" :key="item.id" class="div1">
            <ul>
              <li v-for="(k, index) in item.dxlcxq" :key="k.id">
                <span
                  class="itemtit"
                  @click="$router.push({ path: '/dxlc', query: { id: k.id } })"
                >
                  {{ index + 1 }}.{{ k.title }}
                </span>
                <el-select
                  placeholder="标记"
                  v-model="k.sign"
                  size="small"
                  @change="sign(k.sign, k.id)"
                >
                  <el-option value="1" label="已做"> </el-option>
                  <el-option value="2" label="未做"> </el-option>
                  <el-option value="3" label="进行"> </el-option>
                  <el-option value="4" label="提醒"> </el-option>
                </el-select>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="article">
        <div class="main-title">
          <div class="title-left">
            <span>成长路径</span>
          </div>
          <div class="title-right" @click="$router.push('/grow')">
            <span>查看更多</span>
            <img :src="path + 'imgs/image/home/3.png'" alt="" />
          </div>
        </div>
        <div class="article-box">
          <div>
            <p style="margin-left: 10px">升学就业</p>
            <ul>
              <li
                :style="{
                  fontWeight: item.topup ? '700' : '',
                  listStyle: item.topup ? 'none' : 'disc',
                }"
                v-for="item in czlj.sxjy"
                :key="item"
                @click="
                  $router.push({
                    path: 'articledetails',
                    query: { sign: 1, id: item.id },
                  })
                "
              >
                <p :style="{ height: item.topup ? '45px' : '42px' }">
                  {{ item.title }}
                </p>
              </li>
            </ul>
          </div>
          <div>
            <p style="margin-left: 10px">优秀校友</p>
            <ul>
              <li
                :style="{
                  fontWeight: item.topup ? '700' : '',
                  listStyle: item.topup ? 'none' : 'disc',
                }"
                v-for="item in czlj.yxxy"
                :key="item"
                @click="
                  $router.push({
                    path: 'articledetails',
                    query: { sign: 2, id: item.id },
                  })
                "
              >
                <p :style="{ height: item.topup ? '45px' : '42px' }">
                  {{ item.title }}
                </p>
              </li>
            </ul>
          </div>
          <div>
            <p style="margin-left: 10px">职业口述</p>
            <ul>
              <li
                :style="{
                  fontWeight: item.topup ? '700' : '',
                  listStyle: item.topup ? 'none' : 'disc',
                }"
                v-for="item in czlj.zyks"
                :key="item"
                @click="
                  $router.push({
                    path: 'articledetails',
                    query: { sign: 3, id: item.id },
                  })
                "
              >
                <p :style="{ height: item.topup ? '45px' : '42px' }">
                  {{ item.title }}
                </p>
              </li>
            </ul>
          </div>
          <div>
            <p style="margin-left: 10px">规划故事</p>
            <ul>
              <li
                :style="{
                  fontWeight: item.topup ? '700' : '',
                  listStyle: item.topup ? 'none' : 'disc',
                }"
                v-for="item in czlj.ghgs"
                :key="item"
                @click="
                  $router.push({
                    path: 'articledetails',
                    query: { sign: 4, id: item.id },
                  })
                "
              >
                <p :style="{ height: item.topup ? '45px' : '42px' }">
                  {{ item.title }}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="videoOrpost">
        <div class="home-video-m">
          <div class="video-title">
            <div class="video-left">
              <span>视频区</span>
            </div>
            <div class="video-right" @click="$router.push({ path: '/video' })">
              <span>查看更多</span>
              <img src="imgs/image/home/3.png" alt="" />
            </div>
          </div>
          <div class="video_model">
            <div class="video-items">
              <div
                class="video-img"
                @click="
                  $router.push({
                    path: '/videoDetails',
                    query: { sign: item.sign, id: item.id },
                  })
                "
                v-for="(item, index) in video.fir"
                :key="index"
              >
                <div class="video">
                  <img :src="path + 'imgs/image/video/6.png'" alt="" />
                  <video
                    :src="url + item.url"
                    width="100%"
                    height="100%"
                    style="object-fit: fill"
                  ></video>
                </div>
                <div class="video-name">
                  <span>{{ item.title }}</span>
                </div>
              </div>
            </div>
            <div class="video-list">
                <ul>
                  <li
                    :style="{ listStyle: 'disc', marginTop:'0',marginBottom:'10px',}"
                    v-for="(item) in video.sec"
                    :key="item"
                    @click="
                      $router.push({
                        path: '/videoDetails',
                        query: { sign: item.sign, id: item.id },
                      })
                    "
                  >
                    <p :style="{ height: item.topup ? '45px' : '42px' }">{{ item.title }}</p>
                  </li>
                </ul>
            </div>
          </div>
        </div>
        <div class="post-m">
          <div class="post-title">
            <div class="post-left">
              <span>岗位区</span>
            </div>
            <div class="post-right" @click="$router.push('/post')">
              <span>查看更多</span>
              <img :src="path + 'imgs/image/home/3.png'" alt="" />
            </div>
          </div>
          <div class="post-left-item">
            <div class="post-left-top">
              <p class="t">招聘公告</p>
              <ul>
                  <li
                    :style="{
                      fontWeight: item.topup ? '700' : '',
                      listStyle: item.topup ? 'none' : 'disc',
                    }"
                    v-for="item in jydw"
                    :key="item"
                    @click="
                      $router.push({
                        path: 'articledetails',
                        query: { sign: 1, id: item.id, type: item.id },
                      })
                    "
                  >
                    <p :style="{ height: item.topup ? '45px' : '42px',height: item.topup ? '45px' : '42px' }">
                      {{ item.title }}
                    </p>
                  </li>
              </ul>
            </div>
            <div class="post-left-top">
              <p class="t">行业动态</p>
              <ul>
                  <li
                    :style="{
                      fontWeight: item.topup ? '700' : '',
                      listStyle: item.topup ? 'none' : 'disc',
                    }"
                    v-for="item in hydt"
                    :key="item"
                    @click="
                      $router.push({
                        path: 'articledetails',
                        query: { id: item.id, sign: 3, type: post },
                      })
                    "
                  >
                    <p :style="{ height: item.topup ? '45px' : '42px' }">
                      {{ item.title }}
                    </p>
                  </li>
              </ul>
            </div>
          </div>
          <div class="post-right-item">
            <p>最新岗位</p>
            <div
              class="item"
              v-for="item in zxgw"
              :key="item"
              @click="
                $router.push({ path: 'jobdetails', query: { id: item.id } })
              "
            >
              <div class="top1">
                <span style="width: 180px;">{{ item.name }}</span>
                <span>
                  {{ item.price }}
                </span>
              </div>
              <div class="top2">
                <span>{{ item.lesson }}</span>
                <span>|</span>
                <span>{{ item.learn }}</span>
              </div>
              <div class="top3">
                <span>{{ item.company }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="subject">
        <div class="subject-box-left">
          <div class="subject-title">
            <div class="subject-left">
              <span>问答区</span>
            </div>
            <div class="subject-right" @click="$router.push('/area')">
              <span>查看更多</span>
              <img :src="path + 'imgs/image/home/3.png'" alt="" />
            </div>
          </div>
          <div class="cont">
            <div class="img-left">
              <img :src="path + 'imgs/image/home/login/16.png'" alt="" />
            </div>
            <div class="con-right">
              <div class="cont-box" v-if="wdqone.one">
                <p>{{ wdqone.one.problem }}</p>
                <div>
                  <span class="answer" >
                    {{ wdqone.one.outline }}
                  </span>
                  <span
                    @click="
                      $router.push({
                        path: 'information',
                        query: { id: wdqone.one.id },
                      })
                    "
                    >阅读全文>>></span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="cont-bottom">
            <div class="text-left">
             <ul v-if="wdq">
              <li  v-for="item in wdq"
                :key="item.id"
                @click="
                $router.push({ path: 'information', query: { id: item.id } })"
                 :style="{
                  listStyle:  'disc', }"
                  >
                <p>
                {{ item.problem }}
              </p>
              </li>
             </ul>
            </div>
          </div>
        </div>
        <div class="subject-box-right">
          <div class="subject-title">
            <div class="subject-left">
              <span>真题区</span>
            </div>
            <div class="subject-right" @click="$router.push('/question')">
              <span>查看更多</span>
              <img :src="path + 'imgs/image/home/3.png'" alt="" />
            </div>
          </div>
          <div class="zxs-bnner">
            <img :src="path + 'imgs/image/home/login/17.png'" alt="" />
          </div>
          <div class="potic">
            <!-- fontWeight: item.topup ? '700' : ''  -->
            <li
              v-for="item in ztq"
              :key="item"
              :style="{ }"
              :class="{ img: item.type === 1, li: item.type === 2 }"
              @click="
                getUrl(item.url, item.title, item.id, item.sign, item.type)
              "
            >
              <span>{{ item.title }}</span>
            </li>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      v-model="dialogVisible"
      :title="titles"
      width="800"
      height="600"
      :before-close="handleClose"
      lock-scroll
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :fullscreen="true"
    >
      <template #header>
        <!-- <h3 style="float: left">{{ titles }}</h3>
        <div
          class="full"
          style="float: right; cursor: pointer"
          @click="full = !full"
        >
          <el-icon><FullScreen /></el-icon>
        </div> -->
      </template>
      <iframe
        v-if="ppt"
        :src="`https://view.officeapps.live.com/op/view.aspx?src=${
          url + ppt
        }#toolbar=0&wdOrigin=BROWSELINK`"
        width="100%"
        height="700"
        frameborder="0"
      ></iframe>
      <vue-office-pdf
        v-if="pdfUrl"
        :src="url + pdfUrl"
        style="width: 100%; height: 100%"
        v-show="true"
      />
    </el-dialog>

    <bottomPageVue></bottomPageVue>
  </div>
</template>

<script setup>
// import { FullScreen } from '@element-plus/icons-vue'
import VueOfficePdf from '@vue-office/pdf'
import '@/assets/css/homePage/homePage.scss'
import Head from '@/components/headerPage.vue'
import bottomPageVue from '@/components/bottomPage.vue'
import { ref, onMounted, reactive, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { isMobile, isPassword } from '@/utils/regexp'
import { ElMessage } from 'element-plus'
import {
  login,
  countryCode,
  send,
  mobilelogin,
  creatQrcode,
  polling
} from '@/api/login'
import { setCourseMark } from '@/api/course'
import { getHomeData } from '@/api/home'
import store from '@/store'
import { v4 as uuidv4 } from 'uuid'

// const full = ref(false)
const url = localStorage.getItem('url')
const path = localStorage.getItem('path')
const router = useRouter()
const loginTab = ref(1)
const flag = ref(false)
const mobile = ref('') // 手机号
const password = ref('') // 密码
const yzm = ref('')
const agree = ref(false)
const dialogVisible = ref(false)
const Countrycodes = ref(null) // 各国区号
const countrycodes = ref('+86') // 获取各国区号
const dxlc = ref([])
const czlj = ref([])
const video = ref([])
const jydw = ref([])
const hydt = ref([])
const zxgw = ref([])
const wdq = ref([])
const ztq = ref([])
const post = ref(uuidv4())
const pdfUrl = ref('')
const ppt = ref('')
const titles = ref('')
const btn = ref(false)
let timer = ref(null)
const m = ref(60)
const xcxQrcode = ref('')
let lx = null
const wdqone = reactive({
  one: ''
})
const sign = async (val, id) => {
  await setCourseMark(id, val)
}
const changeFlag = async () => {
  flag.value = !flag.value
  if (flag.value) {
    const res = await creatQrcode()
    const { scene, xcxqrcode } = res.data
    xcxQrcode.value = xcxqrcode
    lx = setInterval(async () => {
      const res1 = await polling(scene)
      if (res1.data.code === 1) {
        ElMessage({
          message: res1.data.msg,
          type: 'success'
        })
        flag.value = false
        clearInterval(lx)
        const { token, fillin, username, avatar, alias, identity, hyfl_id } = res1.data.data
        store.commit('user/setUserInfo', {
          token,
          fillin,
          username,
          avatar,
          alias,
          identity,
          hyfl_id
        })
        window.location.reload()
      }
      if (!flag.value) {
        clearInterval(lx)
      }
    }, 2000)
  }
}
const changeTab = (index) => {
  loginTab.value = index
}
const getUrl = (path, name, id, sign, type) => {
  if (type === 1) {
    openwj(path, name)
  } else {
    router.push({ path: 'ztqwz', query: { id: id, sign } })
  }
}
const openwj = async (path, name) => {
  const index = path.lastIndexOf('.')
  titles.value = name
  if (index !== -1) {
    const file = path.substring(index)
    if (file === '.pptx' || file === '.ppt') {
      ppt.value = path
      pdfUrl.value = ''
    } else {
      ppt.value = ''
      pdfUrl.value = path
    }
  }
  dialogVisible.value = true
}
const logins = async (i) => {
  if (i === 0) {
    if (!isMobile(mobile.value)) {
      ElMessage({
        message: '手机号格式不正确',
        type: 'warning'
      })
      return
    }
    if (!isPassword(password.value)) {
      ElMessage({
        message: '密码6-30位，不能包含空格',
        type: 'warning'
      })
      return
    }
    if (agree.value) {
      const res = await login(mobile.value, password.value)
      if (!res) return
      if (res.data.code === 1) {
        ElMessage({
          message: res.data.msg,
          type: 'success'
        })
      } else {
        ElMessage({
          message: res.data.msg,
          type: 'error'
        })
      }
      const { token, fillin, username, avatar, alias, identity, hyfl_id } = res.data.data.userinfo
      store.commit('user/setUserInfo', {
        token,
        fillin,
        username,
        avatar,
        alias,
        identity,
        hyfl_id
      })
      window.location.reload()
    } else {
      ElMessage({
        message: '勾选同意',
        type: 'warning'
      })
    }
  }
  if (i === 1) {
    if (!isMobile(mobile.value)) {
      return ElMessage({
        message: '手机号格式不正确',
        type: 'warning'
      })
    }
    if (yzm.value === '') {
      return ElMessage({
        message: '请输入验证码',
        type: 'warning'
      })
    }
    if (agree.value) {
      const res = await mobilelogin(mobile.value, yzm.value)
      if (res.data.code === 1) {
        const { token, fillin, username, avatar, alias, identity, hyfl_id } = res.data.data.userinfo
        store.commit('user/setUserInfo', {
          token,
          fillin,
          username,
          avatar,
          alias,
          identity,
          hyfl_id
        })
        window.location.reload()
      } else {
        ElMessage.error(res.data.msg)
      }
    } else {
      ElMessage({
        message: '请同意用户协议',
        type: 'warning'
      })
    }
  }
}
const zone = async () => {
  const res = await countryCode()
  Countrycodes.value = res.data.data
}
const getHome = async () => {
  const res = await getHomeData()

  dxlc.value = res.data.data.dxlc || ''
  czlj.value = res.data.data.czlj || []
  video.value = res.data.data.video || []
  jydw.value = res.data.data.zpgg || []
  hydt.value = res.data.data.hydt || []
  zxgw.value = res.data.data.zxgw || []
  wdq.value = res.data.data.wdq || []
  wdqone.one = wdq.value.splice(wdq.value.findIndex(item => item.topup === 1), 1)[0]
  ztq.value =
    [
      ...res.data.data.ztq.hybps,
      ...res.data.data.ztq.jyzt,
      ...res.data.data.ztq.zybps,
      ...res.data.data.ztq.zyzt
    ] || []
  dxlc.value.forEach((item) => {
    item.dxlcxq.forEach((k) => {
      switch (k.sign) {
        case 0:
          k.sign = '标记'
          break
        case 1:
          k.sign = '已做'
          break
        case 2:
          k.sign = '未做'
          break
        case 3:
          k.sign = '进行'
          break
        case 4:
          k.sign = '提醒'
          break
        default:
          k.sign = '标记'
          break
      }
    })
  })
}
const Send = async () => {
  if (!isMobile(mobile.value)) {
    ElMessage({
      message: '手机号格式不正确',
      type: 'warning'
    })
    return
  }
  if (!agree.value) {
    ElMessage({
      message: '未同意用户协议',
      type: 'warning'
    })
    return
  }
  btn.value = true
  clearInterval(timer)
  timer = setInterval(() => {
    m.value--
    if (m.value <= 0) {
      m.value = 60
      clearInterval(timer)
      btn.value = false
    }
  }, 1000)
  await send(countrycodes.value.replace(/\+/g, ''), mobile.value)
}
onUnmounted(() => {
  clearInterval(lx)
})
onMounted(() => {
  zone()
  getHome()
})
</script>

<style scoped>
</style>
