import request from '@/utils/request'
// 成长路径

export const getGrowList = (keywords) => {
  return request({
    method: 'GET',
    url: '/api/path/index',
    params: {
      keywords
    }
  })
}
// 升学就业

export const getObtain = (page, limit, keywords) => {
  return request({
    method: 'GET',
    url: '/api/path/obtain',
    params: {
      page,
      limit,
      keywords
    }
  })
}
// 优秀校友

export const getAlumni = (page, limit, keywords) => {
  return request({
    method: 'GET',
    url: '/api/path/alumni',
    params: {
      page,
      limit,
      keywords
    }
  })
}
// 职业口述

export const getMetier = (page, limit, keywords) => {
  return request({
    method: 'GET',
    url: '/api/path/metier',
    params: {
      page,
      limit,
      keywords
    }
  })
}
// 规划故事

export const getStory = (page, limit, keywords) => {
  return request({
    method: 'GET',
    url: '/api/path/story',
    params: {
      page,
      limit,
      keywords
    }
  })
}
// 详情
export const getDetails = (id, sign) => {
  return request({
    method: 'GET',
    url: '/api/path/details',
    params: {
      id,
      sign
    }
  })
}
// 搜索页面搜索
export const search = (page, limit, keywords) => {
  return request({
    method: 'GET',
    url: '/api/path/search',
    params: {
      page,
      limit,
      keywords
    }
  })
}
