import request from '@/utils/request'
// 岗位区

export const getPostList = (keywords) => {
  return request({
    method: 'GET',
    url: '/api/post/index',
    params: {
      keywords
    }
  })
}
// 招聘公告
export const getObtain = (page, limit, keywords, sign) => {
  return request({
    method: 'GET',
    url: '/api/post/notice',
    params: {
      page,
      limit,
      keywords,
      sign
    }
  })
}
// 就业单位详情
export const getDetjydw = (id, sign) => {
  return request({
    method: 'GET',
    url: '/api/post/detjydw',
    params: {
      id,
      sign
    }
  })
}
// 最新岗位
export const getPost = (page, limit, keywords) => {
  return request({
    method: 'GET',
    url: '/api/post/post',
    params: {
      page,
      limit,
      keywords
    }
  })
}
export const getDetzxgw = (id, sign) => {
  return request({
    method: 'GET',
    url: '/api/post/detzxgw',
    params: {
      id,
      sign
    }
  })
}
// 行业动态
export const getDynamic = (page, limit, keywords) => {
  return request({
    method: 'GET',
    url: '/api/post/trade',
    params: {
      page,
      limit,
      keywords
    }
  })
}
// 工作感受
export const getTask = (page, limit, keywords) => {
  return request({
    method: 'GET',
    url: '/api/post/task',
    params: {
      page,
      limit,
      keywords
    }
  })
}
// 详情
export const postDetails = (id, sign) => {
  return request({
    method: 'GET',
    url: '/api/post/details',
    params: {
      id,
      sign
    }
  })
}
// 单位收藏
export const dwcol = (id) => {
  return request.post('/api/post/dwcol', {
    id
  })
}
// 岗位收藏
export const gwcol = (id) => {
  return request.post('/api/post/gwcol', {
    id
  })
}
// 取消岗位收藏
export const gwcolqx = (id) => {
  return request.post('/api/post/gwcolqx', {
    id
  })
}
// 取消岗位收藏
export const dwcolqx = (id) => {
  return request.post('/api/post/dwcolqx', {
    id
  })
}
// 搜索页搜索
export const search = (page, limit, keywords) => {
  return request({
    method: 'GET',
    url: '/api/post/search',
    params: {
      page,
      limit,
      keywords
    }
  })
}
