const INFO_KEY = 'user_info'
export const getinfo = () => {
  const defaultObj = { token: '', fillin: '', usname: '', avatar: '' }
  const result = sessionStorage.getItem(INFO_KEY)
  return result ? JSON.parse(result) : defaultObj
}
export const setinfo = (obj) => {
  sessionStorage.setItem(INFO_KEY, JSON.stringify(obj))
}
export const removeinfo = () => {
  sessionStorage.removeItem(INFO_KEY)
}
