<template>
  <div class="postPage">
<headerPage></headerPage>
<serchPage @serch="serchGrow"></serchPage>
<div class="grow-m">
      <div class="grow-box">
        <div class="grow-item post-list">
            <div class="grow-title">
          <div class="grow-left">
            <span>招聘公告</span>
          </div>
          <div class="grow-right" @click="link('/unit')">
            <span>查看更多</span>
            <img :src="path + 'imgs/image/home/3.png'" alt="">
          </div>
          </div>
          <div class="grow-item-article" v-for="item in jydw.list" :key="item"  @click="$router.push({path:'articledetails',query:{id:item.id,sign:jydw.sign,type:post}})">
            <div class="grow-item-article-left" v-if="item.head_img">
              <img :src="url+item.head_img" alt="">
            </div>
            <div class="grow-item-article-right">
              <div class="grow-item-article-title">
                <p>{{item.title}}</p>

              </div>
              <div class="grow-item-article-text">
                <span>{{ item.outline }}</span>
              </div>
              <div class="grow-item-article-timer">
                <span>
                  {{ item.time}}
                  </span>
              </div>
            </div>
          </div>

        </div>
        <div class="grow-item post-list">
          <div class="grow-title">
          <div class="grow-left">
            <span>最新岗位</span>
          </div>
          <div class="grow-right" @click="link('/latestposition')">
            <span>查看更多</span>
            <img :src="path + 'imgs/image/home/3.png'" alt="">
          </div>
          </div>
          <div class="post-item" v-for="item in zxgw.list" :key="item" @click="$router.push({path:'jobdetails',query:{id:item.id,sign:sign}})">
              <div class="top1">
                <span  style="width: 170px;">{{ item.name }}</span>
                <span >
                  {{ item.price }}
                </span>
              </div>

              <div class="top3">
                <span>{{ item.company }}</span>
              </div>
            </div>
        </div>
        <div class="grow-item post-list">
          <div class="grow-title">
          <div class="grow-left">
            <span>行业动态</span>
          </div>
          <div class="grow-right" @click="link('/dynamic')">
            <span>查看更多</span>
            <img :src="path + 'imgs/image/home/3.png'" alt="">
          </div>
          </div>
          <div class="grow-item-article" v-for="item in hydt.list" :key="item" @click="$router.push({path:'articledetails',query:{id:item.id,sign:hydt.sign,type:post}})">
            <div class="grow-item-article-left" v-if="item.head_img">
              <img :src="url+item.head_img" alt="">
            </div>
            <div class="grow-item-article-right">
              <div class="grow-item-article-title">
                <p>{{ item.title }}</p>
              </div>
              <div class="grow-item-article-text">
                <span>{{ item.outline }}</span>
              </div>
              <div class="grow-item-article-timer">
                <span>
                  {{ item.time}}
                  </span>
              </div>
            </div>
          </div>
        </div>
        <div class="grow-item post-list">
          <div class="grow-title">
          <div class="grow-left">
            <span>工作感受</span>
          </div>
          <div class="grow-right" @click="link('/feel')">
            <span>查看更多</span>
            <img :src="path + 'imgs/image/home/3.png'" alt="">
          </div>
          </div>
          <div class="grow-item-article" v-for="item in gzgs.list" :key="item" @click="$router.push({path:'articledetails',query:{id:item.id,sign:gzgs.sign,type:post}})">
            <div class="grow-item-article-left" v-if="item.head_img">
              <img :src="url+item.head_img" alt="">
            </div>
            <div class="grow-item-article-right">
              <div class="grow-item-article-title">
                <p>{{ item.title }}</p>
              </div>
              <div class="grow-item-article-text" >
                <span>{{ item.outline }}</span>
              </div>
              <div class="grow-item-article-timer">
                <span>
                  {{ item.time}}
                  </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bottomPage></bottomPage>
  </div>
</template>

<script setup>
import '@/assets/css/post/post.scss'
import headerPage from '@/components/headerPage.vue'
import serchPage from '@/components/serchPage.vue'
import bottomPage from '@/components/bottomPage.vue'
import { ElMessage } from 'element-plus'
import { ref, onMounted } from 'vue'
import { getPostList } from '@/api/post'
import { useRouter } from 'vue-router'
import { v4 as uuidv4 } from 'uuid'
const router = useRouter()
const url = localStorage.getItem('url')
const path = localStorage.getItem('path')
const jydw = ref([])
const zxgw = ref([])
const hydt = ref([])
const gzgs = ref([])
const post = ref(uuidv4())
const link = (link) => {
  router.push(link)
}
const getPost = (val) => {
  const res = getPostList(val)

  res.then((response) => {
    if (response.data && response.data.data) {
      jydw.value = response.data.data.zpgg
      zxgw.value = response.data.data.zxgw
      hydt.value = response.data.data.hydt
      gzgs.value = response.data.data.gzgs
    } else {
      // 如果没有找到任何结果，显示错误消息
      ElMessage.warning('暂无信息')
    }
  }).catch((error) => {
    // 如果Promise被拒绝（例如，网络错误、服务器错误等），则处理错误
    console.error('请求失败：', error)
  })
}
const serchGrow = async (val) => {
  router.push({ path: '/serch', query: { keywods: val, sign: 4 } })
}
onMounted(() => {
  getPost() // 如果需要参数，请传递正确的参数
})

</script>

<style>

</style>
