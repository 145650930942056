<template>
    <div class="footer">
     <div class="footer-box">
      <div class="footer-box-item">
          <div class="footer-box-item-s">
              <img :src="path + 'imgs/image/login/10.png'" alt="">
              <p style="font-weight: 600">服务热线：{{ footerList.hotline }}</p>
              <p  style="font-weight: 600">企业邮箱：{{ footerList.mailbox }}</p>
              <p style="color: #999999;">工作时间：{{ footerList.starttime }}-{{ footerList.endtime }}</p>
          </div>
          <div class="footer-box-item-s">
              <ul>
                  关于我们
                  <li style="font-size: 14px; cursor: pointer;" @click="$router.push('/companies')">公司简介</li>
              </ul>
          </div>
          <div class="footer-box-item-s">
              <ul>
                  用户帮助
                  <li style="font-size: 14px;" @click="$router.push({path:'loginpolicy',query:{id:5}})">用户协议</li>
                  <li style="font-size: 14px;" @click="$router.push({path:'loginpolicy',query:{id:4}})">隐私政策</li>
              </ul>
          </div>
          <div class="footer-box-item-s">
              <div class="code">
              <div class="code1">
                <img :src="url+footerList.applet" alt="">
              </div>
          </div>
          <p style="color: #373737;">扫码查看小程序</p>
          </div>
          <div class="footer-box-item-s">
              <div class="code">
              <div class="code1">
                <img :src="url+footerList.wechat" alt="">
              </div>
          </div>
          <p style="color: #373737;">扫码添加客服微信</p>
          </div>
      </div>
      <div class="bottom">
          <div class="bottom-top">
              <span>网站ICP备案/许可证号：<a style="color: #666666;" href="http://beian.miit.gov.cn/">{{ footerList.license }}</a> ┃ <a  rel="noreferrer" target="_blank" style="color: #666666;" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37010202700296">
                <img  src="/imgs/image/wz/wz.png" alt="" style="width: 20px; height: 20px;vertical-align: middle; margin-bottom: 3px;">
                鲁公网安备37010202700296号</a> ┃ 联系我们 {{ footerList.mailbox }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;服务热线 {{ footerList.hotline }}</span>
          </div>
          <div class="bottom-bottom">
              <span>{{ footerList.copyright }}</span>
          </div>
      </div>
     </div>
    </div>
  </template>

<script setup>
import { ref, onMounted } from 'vue'
import { footer } from '@/api/footer'
const footerList = ref([])
const url = localStorage.getItem('url')
const getfooter = async () => {
  const res = await footer()
  footerList.value = res.data.data
}
onMounted(() => {
  getfooter()
})
const path = ref(localStorage.getItem('path')).value
</script>

  <style scoped lang="scss">
.footer{
    width: 100%;
    height: 280px;
    background-color: white;
    margin-top: 60px;
    min-width: 1200px;
    border-top: 1px solid #d9d9d9;
    .footer-box{
        width: 1190px;
        height: 100%;
        margin: 0 auto;
       .footer-box-item{
        height: 200px;
        width: 100%;
        display: flex;
        justify-content: space-between;
       .footer-box-item-s{
            width: auto;
            height: auto;
            margin-top: 20px;
            ul{
                margin-top: 20px;
            }
            li{
                margin-top: 10px;
                color: #999999;
                cursor: pointer;
            }
            p{
                margin-top: 5px;
            }

        }
        .code{
            width: 117px;
            height: 117px;
            border: 1px solid #ebe7e2;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #373737;
            .code1{
                width: 101px;
                height: 101px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
       }
       .bottom{
        width: 100%;
        border-top:1px solid #f2f2f2;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
        color: #666666;
        .bottom-top{
            width: 100%;
            height: auto;
            text-align: center;
        }
        .bottom-bottom{
            width: 100%;
            height: auto;
            text-align: center;
        }
       }
    }
}

  </style>
