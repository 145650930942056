import request from '@/utils/request'
// 首页
export const getVideoList = (page, limit, keywords) => {
  return request({
    method: 'GET',
    url: '/api/video/index',
    params: {
      page,
      limit,
      keywords
    }
  })
}
// 就业分享
export const getObtain = (page, limit, keywords) => {
  return request({
    method: 'GET',
    url: '/api/video/obtain',
    params: {
      page,
      limit,
      keywords
    }
  })
}
// 面试分享
export const getIntervi = (page, limit, keywords) => {
  return request({
    method: 'GET',
    url: '/api/video/intervi',
    params: {
      page,
      limit,
      keywords
    }
  })
}
// 校友分享
export const getAlumni = (page, limit, keywords) => {
  return request({
    method: 'GET',
    url: '/api/video/alumni',
    params: {
      page,
      limit,
      keywords
    }
  })
}
// 他山之石
export const getStone = (page, limit, keywords) => {
  return request({
    method: 'GET',
    url: '/api/video/stone',
    params: {
      page,
      limit,
      keywords
    }
  })
}
// 视频详情

export const getVideoDetail = (id, sign) => {
  return request({
    method: 'GET',
    url: '/api/video/details',
    params: {
      id, sign
    }
  })
}
// 搜索
export const search = (page, limit, keywords) => {
  return request({
    method: 'GET',
    url: '/api/video/search',
    params: {
      page,
      limit,
      keywords
    }
  })
}
