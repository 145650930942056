import { createStore } from 'vuex'
import user from './modules/user'
import serch from './modules/serch'
export default createStore({
  state: {
  },
  getters: {
    token: state => state.user.userinfo.token,
    name: state => state.user.userinfo.username,
    fillin: state => state.user.userinfo.fillin,
    avatar: state => state.user.userinfo.avatar,
    alias: state => state.user.userinfo.alias,
    identity: state => state.user.userinfo.identity,
    hyid: state => state.user.userinfo.hyfl_id
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    serch
  }
})
