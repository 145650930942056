<template>
  <div class="questionPage">
    <headerPage></headerPage>
    <serchPage @serch="serchGrow"></serchPage>
    <div class="question-main">
      <div class="question-content">
        <div class="question-item">
          <div class="grow-title">
          <div class="grow-left">
            <span>行业白皮书</span>
          </div>
          <div class="grow-right" @click="link('/wbook')">
            <span>查看更多</span>
            <img :src="path + 'imgs/image/home/3.png'" alt="">
          </div>
          </div>
          <div class="question-content-item">
           <ul>
            <li v-for="(item,index) in hybpsList" :key="index"  :class="{'img':item.type===1,'li':item.type===2}" @click="getUrl(item.url,item.title,item.id,1,item.type)">
              <span  style="color: black;" >{{ item.title }}</span>
            </li>
           </ul>
          </div>
        </div>
        <div class="question-item">
          <div class="grow-title">
          <div class="div grow-left">
            <span>专业真题</span>
          </div>
          <div class="grow-right" @click="link('/major')">
            <span>查看更多</span>
            <img :src="path + 'imgs/image/home/3.png'" alt="">
          </div>
          </div>
          <div class="question-content-item">
           <ul>
            <li v-for="(item,index) in zyzt" :key="index"  :class="{'img':item.type===1,'li':item.type===2}" @click="getUrl(item.url,item.title,item.id,2,item.type)">
              <span  style="color: black;" >{{ item.title }}</span>
            </li>
           </ul>
          </div>
        </div>
        <div class="question-item">
          <div class="grow-title">
          <div class="grow-left">
            <span>专业白皮书</span>
          </div>
          <div class="grow-right" @click="link('/majoronbook')">
            <span>查看更多</span>
            <img :src="path + 'imgs/image/home/3.png'" alt="">
          </div>
          </div>
          <div class="question-content-item">
           <ul>
            <li v-for="(item,index) in zybps" :key="index" :class="{'img':item.type===1,'li':item.type===2}" @click="getUrl(item.url,item.title,item.id,3,item.type)">
              <span style="color: black;" >{{ item.title }}</span>
            </li>
           </ul>
          </div>
        </div>
        <div class="question-item">
          <div class="grow-title">
          <div class="div grow-left">
            <span>就业真题</span>
          </div>
          <div class="grow-right" @click="link('/obtain')">
            <span>查看更多</span>
            <img :src="path + 'imgs/image/home/3.png'" alt="">
          </div>
          </div>
          <div class="question-content-item">
           <ul>
            <li v-for="(item,index) in jyzt" :key="index"  :class="{'img':item.type===1,'li':item.type===2}" @click="getUrl(item.url,item.title,item.id,4,item.type)">
              <a  style="color: black;" >{{ item.title }}</a>
            </li>
           </ul>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
    v-model="dialogVisible"
    width="800"
    height="600"
    :before-close="handleClose"
    lock-scroll
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :fullscreen="true"
  >
  <template #header>
    <h3 style="float: left;">{{ titles }}</h3>
    <!-- <div class="full" style="float: right; cursor: pointer;" @click="full=!full"><el-icon><FullScreen /></el-icon></div> -->
  </template>
  <iframe v-if="ppt" id="myIframe" :src="`https://view.officeapps.live.com/op/view.aspx?src=${url+ppt}#toolbar=0&wdOrigin=BROWSELINK`" width="100%" height="700" ></iframe>
  <vue-office-pdf
        v-if="pdfUrl"
        :src=url+pdfUrl
        style="width: 100%; height: 100%;"
        v-show="true"
    />
  </el-dialog>

    <bottomPage></bottomPage>
  </div>
</template>

<script setup>
import VueOfficePdf from '@vue-office/pdf'
import headerPage from '@/components/headerPage.vue'
import serchPage from '@/components/serchPage.vue'
import bottomPage from '@/components/bottomPage.vue'
import '@/assets/css/questionPage/questionPage.scss'
import { ref, onMounted, watchEffect } from 'vue'
import { useRouter } from 'vue-router'
import { getSearch } from '@/api/subject'
import { ElMessage } from 'element-plus'
// import { FullScreen } from '@element-plus/icons-vue'
// const full = ref(false)
const router = useRouter()
const dialogVisible = ref(false)
const url = localStorage.getItem('url')
const path = ref(localStorage.getItem('path'))
const hybpsList = ref([])
const jyzt = ref([])
const zybps = ref([])
const zyzt = ref([])
const pdfUrl = ref('')
const ppt = ref('')
// const ppt = ref('')
const titles = ref('')
const link = (path) => {
  router.push(path)
}
const getSerchs = () => {
  const res = getSearch()
  res.then((response) => {
    if (response.data && response.data.data) {
      hybpsList.value = response.data.data.hybps
      zyzt.value = response.data.data.zyzt
      zybps.value = response.data.data.zybps
      jyzt.value = response.data.data.jyzt
    } else {
      // 如果没有找到任何结果，显示错误消息
      ElMessage.error('没有找到')
    }
  }).catch((error) => {
    // 如果Promise被拒绝（例如，网络错误、服务器错误等），则处理错误
    console.error('请求失败：', error)
  })
}
const getUrl = (path, name, id, sign, type) => {
  if (type === 1) {
    openwj(path, name)
  } else {
    router.push({ path: 'ztqwz', query: { id: id, sign } })
  }
}
const openwj = async (path, name) => {
  const index = path.lastIndexOf('.')
  titles.value = name
  if (index !== -1) {
    const file = path.substring(index)
    if (file === '.pptx' || file === '.ppt') {
      ppt.value = path
      pdfUrl.value = ''
    } else {
      ppt.value = ''
      pdfUrl.value = path
    }
  }
  dialogVisible.value = true
}
const serchGrow = async (val) => {
  router.push({ path: '/serch', query: { keywods: val, sign: 8 } })
}
watchEffect(() => {
  // 假设iframe的ID是'myIframe'

})
onMounted(() => {
  getSerchs()
})
</script>

<style>
 /* pdfUrl.value = url + aurl
  console.log(pdfUrl.value)
  let xurl = 'https://view.xdocin.com/view?src='
  xurl += encodeURIComponent(pdfUrl.value)
  const ops = {
    printable: false,
    copyable: false,
    toolbar: false
  }
  console.log(dom)
  for (const a in ops) {
    xurl += '&' + a + '=' + encodeURIComponent(ops[a])
  }
  pdfUrl.value = xurl */
/* @rendered="renderedHandler"
@error="errorHandler" */
</style>
