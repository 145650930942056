import request from '@/utils/request'
// 真题首页搜索
export const getSearch = (keywords) => {
  return request({
    method: 'GET',
    url: '/api/subject/index',
    params: {
      keywords
    }
  })
}
// 行业白皮书
export const getWhite = (page, limit, keywords) => {
  return request({
    method: 'GET',
    url: '/api/subject/trade',
    params: {
      page,
      limit,
      keywords
    }
  })
}
// 专业真题
export const getMajor = (page, limit, keywords) => {
  return request({
    method: 'GET',
    url: '/api/subject/major',
    params: {
      page,
      limit,
      keywords
    }
  })
}
// 专业白皮书
export const getSkin = (page, limit, keywords) => {
  return request({
    method: 'GET',
    url: '/api/subject/skin',
    params: {
      page,
      limit,
      keywords
    }
  })
}
// 就业真题
export const getObtain = (page, limit, keywords) => {
  return request({
    method: 'GET',
    url: '/api/subject/obtain',
    params: {
      page,
      limit,
      keywords
    }
  })
}
// 真题区文章
export const details = (id, sign) => {
  return request({
    method: 'GET',
    url: '/api/subject/details',
    params: {
      id,
      sign
    }
  })
}
// 搜索
export const search = (page, limit, keywords) => {
  return request({
    method: 'GET',
    url: '/api/subject/search',
    params: {
      page,
      limit,
      keywords
    }
  })
}
