<template>
  <div class="area">
    <headerPage></headerPage>
    <serchPage @serch="serchGrow"></serchPage>
    <div class="area-main">
      <div class="area-main-m">
        <div class="xs-answer">
          <div class="grow-title">
          <div class="grow-left">
            <span>小树问答</span>
          </div>
          <div class="grow-right" @click="link('/questions')">
            <span>查看更多</span>
            <img :src="path + 'imgs/image/home/3.png'" alt="">
          </div>
          </div>
          <div class="xs-item" v-for="item in xswd" :key="item">
            <div class="xs-answer-title" @click="$router.push({ path: 'information', query: { id: item.id } })">
              <p>{{ item.problem }}</p>
            </div>
            <div class="xs-answer-text">
              {{  item.outline }}
            </div>
          </div>
        </div>
        <div class="xs-posts">
          <div class="posts" v-if="grftkg">
            <div class="grow-title">
          <div class="grow-left">
            <span>个人发帖</span>
          </div>
          <div class="grow-right" @click="link('/launch')">
            <span>查看更多</span>
            <img :src="path + 'imgs/image/home/3.png'" alt="">
          </div>
          </div>
          <div class="posts-item" v-for="item in grft" :key="item">
            <div class="posts-item-title"  @click="$router.push({path:'/noteDetails',query:{id:item.id}})">
              <span>{{ item.title }}</span>
            </div>
            <div class="posts-item-text">
              <span>
                {{ item.content }}
              </span>
            </div>
            <div class="Zone">
              <div class="like" @click="dz(item.id)">
                <span class="iconfont icon-dianzan" :class="{'dz':item.isDz}"></span>
                <span :class="{'dz':item.isDz}">点赞</span>
              </div>
              <div class="like" @click="$router.push({path:'/noteDetails',query:{id:item.id}})">
                <el-icon><ChatDotSquare /></el-icon>
                <span>评论</span>
              </div>
              <div class="like" @click="sc(item.id,item.collect)">
                <el-icon :color="item.collect?'#15b6f0':'#999999'"><Star /></el-icon>
                <span :style="{color:item.collect?'#15b6f0':'#999999'}">收藏</span>
              </div>
              <!-- <div class="like">
                <img :src="path+'imgs/image/area/9.png'" alt="">
                <span>转发</span>
              </div> -->
            </div>
          </div>
          </div>
          <div class="circle" v-if="qzlmkg">
            <div class="grow-title">
          <div class="grow-left">
            <span>圈子栏目</span>
          </div>
          <div class="grow-right" @click="link('/boxes')">
            <span>查看更多</span>
            <img :src="path + 'imgs/image/home/3.png'" alt="">
          </div>
          </div>
          <div class="circle-item" v-for="item in qzlm" :key="item">
            <div class="circle-item-title"   @click="$router.push({path:'dynamics',query:{id:item.id}})">
              <span>{{ item.title }}</span>
            </div>
            <div class="circle-item-text">
              <span>
                {{ item.content }}
              </span>
            </div>
            <div class="Zone">
              <div class="like"  @click="Qdz(item.id)">
                <span class="iconfont icon-dianzan" :class="{'dz':item.isDz}"></span>
                <span :class="{'dz':item.isDz}">点赞</span>
              </div>
              <div class="like" @click="$router.push({path:'dynamics',query:{id:item.id}})">
                <el-icon><ChatDotSquare /></el-icon>
                <span>评论</span>
              </div>
              <div class="like" @click="Qsc(item.id,item.collect)">
                <el-icon  :color="item.collect?'#15b6f0':'#999999'"><Star /></el-icon>
                <span :style="{color:item.collect?'#15b6f0':'#999999'}">收藏</span>
              </div>
              <!-- <div class="like">
                <img :src="path+'imgs/image/area/9.png'" alt="">
                <span>转发</span>
              </div> -->
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
    <bottomPage></bottomPage>
  </div>
</template>

<script setup>
import { Star, ChatDotSquare } from '@element-plus/icons-vue'
import headerPage from '@/components/headerPage.vue'
import serchPage from '@/components/serchPage.vue'
import bottomPage from '@/components/bottomPage.vue'
import '@/assets/css/area/area.scss'
import '@/icon/iconfont.css'
import { ref, onMounted } from 'vue'
import { getQaList, fttzdz, qzdtdz, tzcol, dtcol, tzcolxq, dtcolqx } from '@/api/qa'
import { useRouter } from 'vue-router'
const router = useRouter()
// const url = localStorage.getItem('url')
const path = localStorage.getItem('path')
const xswd = ref([])
const grft = ref([])
const qzlm = ref([])
const qzlmkg = ref(null)
const grftkg = ref(null)
const link = (link) => {
  router.push(link)
}
const getQa = async () => {
  const res = await getQaList()
  if (res.data.data) {
    xswd.value = res.data.data.xswd || []
    grft.value = res.data.data.grft
    qzlm.value = res.data.data.qzlm
    qzlmkg.value = res.data.data.qzlmkg
    grftkg.value = res.data.data.grftkg
  }
  getLocalStorageData()
}

const serchGrow = async (val) => {
  router.push({ path: '/serch', query: { keywods: val, sign: 6 } })
}
const dz = async (id) => {
  const res = await fttzdz(id)
  const isDz = res.data.data
  if (grft.value) {
    grft.value.forEach(item => {
      if (item.id === id) {
        item.isDz = isDz
      }
    })
  }

  localStorage.setItem(`dz_${id}`, isDz ? 'true' : 'false')
  getLocalStorageData()
}
const Qdz = async (id) => {
  const res = await qzdtdz(id)
  const isDz = res.data.data
  if (qzlm.value) {
    qzlm.value.forEach(item => {
      if (item.id === id) {
        item.isDz = res.data.data
      }
    })
  }
  localStorage.setItem(`dz_${id}`, isDz ? 'true' : 'false')
  getLocalStorageData()
}
const sc = async (id, collect) => {
  if (collect === 1) {
    await tzcolxq(id)
  } else {
    await tzcol(id)
  }
  getQa()
}
const Qsc = async (id, collect) => {
  if (collect === 1) {
    await dtcolqx(id)
  } else {
    await dtcol(id)
  }
  getQa()
}
const getLocalStorageData = () => {
  if (grft.value) {
    grft.value.forEach(item => {
      const dz = localStorage.getItem(`dz_${item.id}`)
      if (dz !== null) {
        item.isDz = dz === 'true'
      }
    })
  }
  if (qzlm.value) {
    qzlm.value.forEach(item => {
      const dz = localStorage.getItem(`dz_${item.id}`)
      if (dz !== null) {
        item.isDz = dz === 'true'
      }
    })
  }
}

onMounted(() => {
  getQa()
})

</script>

<style>

</style>
