import request from '@/utils/request'
// 大学历程
export const getCourseList = () => {
  return request.get('/api/course/index')
}
// 标记
export const setCourseMark = (id, sign) => {
  return request.post('/api/course/mark', {
    id,
    sign
  })
}
// 大学历程文章列表
export const wzlist = (id, page, limit, keywords) => {
  return request({
    method: 'GET',
    url: '/api/course/wzlist',
    params: {
      id,
      page,
      limit,
      keywords
    }
  })
}
// 大学历程详情
export const details = (id) => {
  return request({
    method: 'GET',
    url: '/api/course/details',
    params: {
      id

    }
  })
}
// 搜索
export const search = (page, limit, keywords) => {
  return request({
    method: 'GET',
    url: '/api/course/search',
    params: {
      page, limit, keywords
    }
  })
}
