<template>
  <div class="grow">
    <headerPage></headerPage>
    <serchPage @serch="serchGrow"></serchPage>
    <div class="grow-m">
      <div class="grow-box">
        <div class="grow-item">
            <div class="grow-title">
          <div class="grow-left">
            <span>升学就业</span>
          </div>
          <div class="grow-right" @click="link('/continuing')">
            <span>查看更多</span>
            <img :src="path + 'imgs/image/home/3.png'" alt="">
          </div>
          </div>
          <div class="grow-item-article" v-for="item in sxjy.list" :key="item" @click="$router.push({path:'/articledetails',query:{sign:sxjy.sign,id:item.id}})">
            <div class="grow-item-article-left" v-if="item.head_img">
              <img :src="url+item.head_img" alt="" >
            </div>
            <div class="grow-item-article-right" :style="{width: item.head_img ? '355px' : '100%'}">
              <div class="grow-item-article-title">
                <p>{{ item.title }}</p>
              </div>
              <div class="grow-item-article-text" >
                <span>{{ item.outline }}</span>
              </div>
              <div class="grow-item-article-timer">
                <span>
                  {{ item.time}}
                  </span>
              </div>
            </div>
          </div>

        </div>
        <div class="grow-item">
          <div class="grow-title">
          <div class="grow-left">
            <span>优秀校友</span>
          </div>
          <div class="grow-right" @click="link('/alumni')">
            <span>查看更多</span>
            <img :src="path + 'imgs/image/home/3.png'" alt="">
          </div>
          </div>
          <div class="grow-item-article" v-for="item in yxxy.list" :key="item" @click="$router.push({path:'/articledetails',query:{sign:yxxy.sign,id:item.id}})">
            <div class="grow-item-article-left" v-if="item.head_img">
              <img :src="url+item.head_img" alt="">
            </div>
            <div class="grow-item-article-right" :style="{width: item.head_img ? '355px' : '100%'}">
              <div class="grow-item-article-title">
                <p>{{ item.title }}</p>
              </div>
              <div class="grow-item-article-text">
                <span>{{ item.outline }}</span>
              </div>
              <div class="grow-item-article-timer">
                <span>
                  {{ item.time}}
                  </span>
              </div>
            </div>
          </div>
        </div>
        <div class="grow-item">
          <div class="grow-title">
          <div class="grow-left">
            <span>职业口述</span>
          </div>
          <div class="grow-right" @click="link('/occupation')">
            <span>查看更多</span>
            <img :src="path + 'imgs/image/home/3.png'" alt="">
          </div>
          </div>
          <div class="grow-item-article" v-for="item in zyks.list" :key="item" @click="$router.push({path:'/articledetails',query:{sign:zyks.sign,id:item.id}})">
            <div class="grow-item-article-left" v-if="item.head_img">
              <img :src="url+item.head_img" alt="">
            </div>
            <div class="grow-item-article-right" :style="{width: item.head_img ? '355px' : '100%'}">
              <div class="grow-item-article-title">
                <p>{{ item.title }}</p>
              </div>
              <div class="grow-item-article-text">
                <span>{{ item.outline }}</span>
              </div>
              <div class="grow-item-article-timer">
                <span>
                  {{ item.time}}
                  </span>
              </div>
            </div>
          </div>
        </div>
        <div class="grow-item">
          <div class="grow-title">
          <div class="grow-left">
            <span>规划故事</span>
          </div>
          <div class="grow-right" @click="link('/story')">
            <span>查看更多</span>
            <img :src="path + 'imgs/image/home/3.png'" alt="">
          </div>
          </div>
          <div class="grow-item-article" v-for="item in ghgs.list" :key="item" @click="$router.push({path:'/articledetails',query:{sign:ghgs.sign,id:item.id}})">
            <div class="grow-item-article-left" v-if="item.head_img">
              <img :src="url+item.head_img" alt=""   >
            </div>
            <div class="grow-item-article-right" :style="{width: item.head_img ? '355px' : '100%'}">
              <div class="grow-item-article-title">
                <p>{{ item.title }}</p>
              </div>
              <div class="grow-item-article-text">
                <span>{{ item.outline }}</span>
              </div>
              <div class="grow-item-article-timer">
                <span>
                  {{ item.time}}
                  </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bottomPage></bottomPage>
  </div>
</template>

<script setup>
import '@/assets/css/grow/grow.scss'
import headerPage from '@/components/headerPage.vue'
import bottomPage from '@/components/bottomPage.vue'
import serchPage from '@/components/serchPage.vue'
import { ref, onMounted } from 'vue'
import { getGrowList } from '@/api/grow'
import { useRouter } from 'vue-router'
const router = useRouter()
const url = localStorage.getItem('url')
const path = localStorage.getItem('path')
const sxjy = ref([])
const yxxy = ref([])
const zyks = ref([])
const ghgs = ref([])
const link = (link) => {
  router.push(link)
}
const getGrow = async () => {
  const res = await getGrowList()

  if (res.data.data) {
    sxjy.value = res.data.data.sxjy
    yxxy.value = res.data.data.yxxy
    zyks.value = res.data.data.zyks
    ghgs.value = res.data.data.ghgs
  }
}
const serchGrow = async (val) => {
  router.push({ path: '/serch', query: { keywods: val, sign: 2 } })
}
onMounted(() => {
  getGrow()
})
</script>

<style>

</style>
