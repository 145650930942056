<template>
  <div class="course-page">
    <headerPage></headerPage>
    <serchPage @serch="serchGrow"></serchPage>
    <div class="course-main">
      <div class="course-box">
        <div class="course-title">
          <div class="course-left">
            <span>{{  $store.getters.identity === '2' ? '大学历程' : '职场历程'  }}</span>
          </div>
        </div>
        <div class="universityBox">
          <div v-for="item in courseList" :key="item.id" >{{ item.title }}</div>
        </div>
        <div class="universityBox-item">
          <div class="un-item1 i" v-for="item in courseList" :key="item.id" >
            <div v-for="k in item.dxlcxq" :key="k.id" class="for1" @click="$router.push({path:'dxlc',query:{id:k.id}})">
              <span class="ti" style="font-size: 16px;">{{ k.title }}</span>
              <el-select placeholder="标记" v-model="k.sign" size="small" @change="sign(k.sign,k.id)">
                <el-option value="1"  label="已做">
                </el-option>
                <el-option value="2" label="未做">
                </el-option>
                <el-option  value="3" label="进行">
                </el-option>
                <el-option  value="4" label="提醒">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bottomPage></bottomPage>
  </div>
</template>

<script setup>
import '@/assets/css/course/course.scss'
import headerPage from '@/components/headerPage.vue'
import serchPage from '@/components/serchPage.vue'
import bottomPage from '@/components/bottomPage.vue'
import { getCourseList, setCourseMark } from '@/api/course'
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const courseList = ref([])
const getcourseList = async () => {
  const res = await getCourseList()
  courseList.value = res.data.data
  courseList.value.forEach(item => {
    item.dxlcxq.forEach(k => {
      switch (k.sign) {
        case 0:
          k.sign = '标记'
          break
        case 1:
          k.sign = '已做'
          break
        case 2:
          k.sign = '未做'
          break
        case 3:
          k.sign = '进行'
          break
        case 4:
          k.sign = '提醒'
          break
        default:
          k.sign = 0
      }
    })
  })
}
const sign = async (val, id) => {
  await setCourseMark(id, val)
}
const serchGrow = (val) => {
  router.push({ path: '/serch', query: { keywods: val, sign: 1 } })
}
onMounted(() => {
  getcourseList()
})
</script>

<style>

</style>
