export default {
  namespaced: true,
  state () {
    return {
      serchList: localStorage.getItem('serchList') ? JSON.parse(localStorage.getItem('serchList')) : {}
    }
  },
  mutations: {
    setserchList (state, data) {
      state.serchList = data
      localStorage.setItem('serchList', JSON.stringify(data))
    }
  },
  actions: {

  },
  getters: {

  }
}
