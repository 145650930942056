
import request from '@/utils/request'
// 问答区
export const getQaList = (keywords) => {
  return request({
    method: 'GET',
    url: '/api/qa/index',
    params: {
      keywords
    }
  })
}
// 小树问答
export const getWenda = (page, limit, keywords) => {
  return request({
    method: 'GET',
    url: '/api/qa/xslist',
    params: {
      page,
      limit,
      keywords
    }
  })
}
// 问答详情
export const getWendaD = (id) => {
  return request({
    method: 'GET',
    url: '/api/qa/xstail',
    params: {
      id
    }
  })
}
// 提交问题
export const submitVal = (problem) => {
  return request({
    method: 'POST',
    url: '/api/qa/turnin',
    data: {
      problem
    }
  })
}
// 栏目列表
export const grList = (page, limit, keywords) => {
  return request({
    method: 'GET',
    url: '/api/qa/grlist',
    params: {
      page,
      limit,
      keywords
    }
  })
}
// 帖子列表
export const tzlist = (id, page, limit, keywords) => {
  return request({
    method: 'GET',
    url: '/api/qa/tzlist',
    params: {
      id,
      page,
      limit,
      keywords
    }
  })
}
// 帖子详情
export const tzdetail = (id) => {
  return request({
    method: 'GET',
    url: '/api/qa/tzdet',
    params: {
      id
    }
  })
}
// 提交帖子
export const tzsub = (id, title, content, picture, set) => {
  return request.post('/api/qa/tzsub', {
    id,
    title,
    content,
    picture,
    set
  })
}
// 提交评论
export const plsub = (id, content, picture, set) => {
  return request.post('/api/qa/plsub', {
    id,
    content,
    picture,
    set
  })
}
// 提交回复
export const plrep = (id, content, set) => {
  return request.post('/api/qa/plrep', {
    id,
    content,
    set
  })
}
// 圈子列表
export const qzlist = (page, limit, keywords) => {
  return request({
    method: 'GET',
    url: '/api/qa/qzlist',
    params: {
      page,
      limit,
      keywords
    }
  })
}
// 圈子详情
export const qzxz = (id) => {
  return request({
    method: 'GET',
    url: '/api/qa/qztail',
    params: {
      id
    }
  })
}
// 圈子动态详情
export const dtdet = (id) => {
  return request({
    method: 'GET',
    url: '/api/qa/dtdet',
    params: {
      id
    }
  })
}
// 提交评论
export const qzplsub = (id, content, picture, set) => {
  return request.post('/api/qa/qzplsub', {
    id,
    content,
    picture,
    set
  })
}
// 回复评论
export const qzplrep = (id, content, set) => {
  return request.post('/api/qa/qzplrep', {
    id,
    content,
    set
  })
}
// 提交动态
export const dtsub = (id, title, content, picture, set) => {
  return request.post('/api/qa/dtsub', {
    id,
    title,
    content,
    picture,
    set
  })
}

// 推荐列表
export const tjlist = (page, limit, keywords) => {
  return request({
    method: 'GET',
    url: '/api/qa/tjlist',
    params: {
      page,
      limit,
      keywords
    }
  })
}
// 帖子点赞
export const fttzdz = (id) => {
  return request.post('/api/qa/fttzdz', {
    id
  })
}
// 圈子栏目点赞
export const qzdtdz = (id) => {
  return request.post('/api/qa/qzdtdz', {
    id
  })
}
// 帖子评论点赞
export const ftpldz = (id) => {
  return request.post('/api/qa/ftpldz', {
    id
  })
}
// 圈子动态点赞
export const qzpldz = (id) => {
  return request.post('/api/qa/qzpldz', {
    id
  })
}
// 加入圈子
export const qzadd = (id) => {
  return request.post('/api/qa/qzadd', {
    id
  })
}
// 帖子加入收藏
export const tzcol = (id) => {
  return request.post('/api/qa/tzcol', {
    id
  })
}
// 圈子收藏
export const dtcol = (id) => {
  return request.post('/api/qa/dtcol', {
    id
  })
}
// 帖子取消收藏
export const tzcolxq = (id) => {
  return request.post('/api/qa/tzcolqx', {
    id
  })
}
// 圈子取消收藏
export const dtcolqx = (id) => {
  return request.post('/api/qa/dtcolqx', {
    id
  })
}
