import request from '@/utils/request'
// 主页图片
export const HomeBnner = () => {
  return request.get('/api/login/index')
}
// 用户协议
export const UserNotece = () => {
  return request.get('/api/login/notice')
}
// 登录
export const login = (mobile, password) => {
  return request.post('/api/login/login', {
    mobile,
    password
  })
}
// 退出登录
export const loginOut = () => {
  return request.post('/api/login/logout')
}
// 各国区号
export const countryCode = () => {
  return request.get('/api/login/area')
}
// 发送验证码
export const send = (code, mobile) => {
  return request.post('/api/sms/send', {
    code,
    mobile
  })
}
// 验证码登录
export const mobilelogin = (mobile, captcha) => {
  return request.post('/api/login/mobilelogin', {
    mobile,
    captcha
  })
}
// 小程序码
export const creatQrcode = () => {
  return request.get('/api/login/creatQrcode')
}
// 登录轮询
export const polling = (scene) => {
  return request({
    method: 'get',
    url: '/api/login/polling',
    params: {
      scene
    }
  })
}
