import { setinfo, getinfo } from '@/utils/storge'

export default {
  namespaced: true,
  state () {
    return {
      userinfo: getinfo() // 获取本地存储的userinfo
    }
  },
  mutations: {
    setUserInfo (state, obj) {
      state.userinfo = obj
      setinfo(obj)
    }
  },
  actions: {

  },
  getters: {

  }
}
